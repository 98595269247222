export default {
  parseJwt(token){
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = Buffer.from(base64, 'base64').toString();
    return JSON.parse(jsonPayload);
  },
  authorize(auth = []){
    let parsedJWTJson = this.parseJwt(this.getSession("user").access_token);
    const scope = parsedJWTJson["scope"];
    const intersection = scope.filter(value => auth.includes(value));
    if (auth.length && intersection.length == 0){
      return false;
    }
    return true;
  },
  hash(str, seed = 0){
    let h1 = 0xdeadbeef ^ seed,
        h2 = 0x41c6ce57 ^ seed;
    for(let i = 0, ch; i < str.length; i++){
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  },
  getEnv(name){
    if(name.indexOf('VUE_APP_') == -1){
      name = 'VUE_APP_' + name
    }
    return process.env[name];
  },
  defaultSetting(){
    let settings = this.getLocal('settings');
    if(settings) return settings;
    
    this.setLocal('settings', {
      theme: {str: 'light', int: false}
    });
  },
  getStorageName(name){
    return this.getEnv('VUE_APP_STROAGE_BASE') +'_'+ (name ? name : 'dummy');
  },
  setSession(name, value, expiry=24){
    expiry = 60*60*expiry*1000;
    value["expiry"] = new Date().getTime() + expiry
    sessionStorage.setItem(this.getStorageName(name), JSON.stringify(value));
  },
  getSession(name){
    name = this.getStorageName(name);
    const itemStr = sessionStorage.getItem(name)
    if (!itemStr) return null

    const item = JSON.parse(itemStr)
    const now = new Date()
    if(now.getTime() > item.expiry){
      sessionStorage.removeItem(name)
      return null
    }
    delete item.expiry
    return item
  },
  delSession(name){
    return sessionStorage.removeItem(this.getStorageName(name));
  },
  setLocal(name, value, expiry=24){
    expiry = 60*60*expiry*1000;
    value["expiry"] = new Date().getTime() + expiry
    localStorage.setItem(this.getStorageName(name), JSON.stringify(value));
  },
  getLocal(key){
    const name = this.getStorageName(key);
    const itemStr = localStorage.getItem(name)
    if (!itemStr) return null

    const item = JSON.parse(itemStr)
    const now = new Date()
    if(now.getTime() > item.expiry){
      localStorage.removeItem(name)
      return null
    }
    delete item.expiry
    return item
  },
  delLocal(name){
    return sessionStorage.removeItem(this.getStorageName(name));
  },
};