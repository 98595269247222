import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const appName = process.env['VUE_APP_NAME']

const routes = [
  {
    path: '/',
    redirect: 'result',
  },
  {
    path: '/result',
    name: 'Result',
    component: () => import('@/views/Result.vue'),
    meta: { layout: 'Blank', title: `Result | ${appName}` },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: { layout: 'Blank', title: `404 | ${appName}` },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

function addDynamicPageTitle(to, from, next) {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(
    el => el.parentNode.removeChild(el),
  )

  if (!nearestWithMeta) {
    return next()
  }

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })
    
    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  }).forEach(tag => document.head.appendChild(tag))
}

router.beforeEach((to, from, next) => {
  const { auth } = to.meta;

  if(auth){
    const currentUser = Vue.tools.getSession('user');
    if(!currentUser){
      return next({ path: '/result' })
    }
    // checking user status
    const parsedJWTJson = Vue.tools.parseJwt(currentUser.access_token);
    if(parsedJWTJson["ust"] === "created" || parsedJWTJson["ast"] === "created"){
      if(to.path === '/setup-accounts') return next()
      next({path: '/setup-accounts'})
    } else if(parsedJWTJson["ust"] === "inactive" || parsedJWTJson["ast"] === "inactive"){
      next({path: '/error-404'})
    } else{
      next()
    }

    if(!Vue.tools.authorize(auth)){
      return next({ path: '/error-404' })
    }
  }

  // Function for adding dynamic page title
  addDynamicPageTitle(to, from, next)

  next()
})

export default router
