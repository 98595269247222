import Api from "@/services/api";
import {
  POLLING_RESULT,
  REQUEST_RESULT,
} from "@/store/_actiontypes";


const actions = {
  [REQUEST_RESULT]({ dispatch, commit }, data){
    let payload = {
      exam: data.exam,
      year: data.year,
      roll: data.roll
    }
    if(data.class != null){
      payload["semester"] = data.class;
    }
    if(data.uuid != null){
      payload["uuid"] = data.uuid;
    }

    return new Promise((resolve, reject) => {
      Api.post(`result-request`, payload).then((response) => {
        resolve(response.data);
      }, (error) => {
        reject(error);
      });
    });
  },

  [POLLING_RESULT]({ commit }, { uuid }){
    return new Promise((resolve, reject) => {
      Api.get(`result-gpd/${uuid}`).then(response => {
        resolve(response.data);
      }, error => {
        reject(error);
      })
    })
  },

};

export const result = {
  namespaced: true,
  actions
};
