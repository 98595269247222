<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>
const defaultLayout = 'Blank'
export default {
  name: 'AppLayout',
  computed: {
    resolveLayout() {
      const layout = this.$route.meta.layout || defaultLayout
      return () => import(`@/layouts/${layout}.vue`)
    }
  },
  created() {
    this.$tools.defaultSetting()
  }
}
</script>
