import Vue from 'vue'
import Vuex from 'vuex'
import { alert } from "@/store/modules/alert";
import { loader } from "@/store/modules/loader";
import { result } from "@/store/modules/result";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    alert,
    loader,
    result
  },
});